import { PATCH } from "@/core/services/store/request.module";

export default {
  methods: {
    patchSubContractor(subcontractor, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (subcontractor) {
          _this.$store
            .dispatch(PATCH, { url: "subcontractor/" + subcontractor, data })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Sub Contractor is required");
        }
      });
    },
    patchSubContractorContactPerson(subcontractor, persons, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (subcontractor) {
          _this.$store
            .dispatch(PATCH, {
              url:
                "subcontractor/" + subcontractor + "/contact-person/" + persons,
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Sub Contractor is required");
        }
      });
    },
    patchSubContractorProperty(subcontractor, properties, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (subcontractor) {
          _this.$store
            .dispatch(PATCH, {
              url:
                "subcontractor/" +
                subcontractor +
                "/property-address/" +
                properties,
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Sub Contractor is required");
        }
      });
    },
    patchSubContractorBilling(subcontractor, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (subcontractor) {
          _this.$store
            .dispatch(PATCH, {
              url: "subcontractor/" + subcontractor + "/billing-address",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              resolve(error);
            });
        } else {
          reject("Sub Contractor is required");
        }
      });
    },
  },
};
