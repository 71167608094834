<template>
  <DetailTemplate
    :customClass="'subcontractor-detail detail-page'"
    v-if="getPermission('subcontractor:view')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="28"
        type="text"
      >
      </v-skeleton-loader>
      <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ getPageTitle }}
      </h1>
    </template>
    <template v-slot:header-action>
      <v-skeleton-loader
        class="custom-skeleton d-inline-block mr-2"
        v-if="pageLoading"
        type="button"
        height="28"
      >
      </v-skeleton-loader>
      <template v-else>
        <v-menu
          v-if="false && createTransactions.length > 0"
          :close-on-click="true"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              v-bind="attrs"
              v-on="on"
              color="cyan"
            >
              Create... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in createTransactions">
              <v-list-item
                :key="index"
                link
                v-on:click="createTransaction(subcontractorArr, item.action)"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <span class="svg-icon svg-icon-lg svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="
                        $assetURL('media/custom-svg/' + item.action + '.svg')
                      "
                    />
                    <!--end::Svg Icon-->
                  </span>
                </v-list-item-icon>
                <v-list-item-title class="font-size-14 mt-2 mb-2">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <template v-if="getPermission('subcontractor:update')">
        <v-menu
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in subcontractorMoreAction">
              <v-list-item
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="subcontractorTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <v-tab
          v-for="(tab, index) in tabs"
          class="font-size-16 font-weight-600 px-8"
          :key="index"
          :href="'#' + tab.key"
          :disabled="tab.disabled"
        >
          <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
            <!--begin::Svg Icon-->
            <inline-svg
              :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
            />
            <!--end::Svg Icon-->
          </span>
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="position: static"
      >
        <v-tabs-items v-model="subcontractorTab">
          <v-tab-item
            v-if="getPermission('subcontractor:view')"
            :value="'subcontractor'"
          >
            <v-row justify="center">
              <v-expansion-panels v-model="panel" inset>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="4" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            Company Details
                          </h2>
                        </v-col>
                        <v-col cols="8">
                          <template
                            v-if="getPermission('subcontractor:update')"
                          >
                            <span v-if="open" key="0" class="float-right">
                              <v-skeleton-loader
                                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                v-if="pageLoading"
                                type="button"
                                height="28"
                                width="200"
                              >
                              </v-skeleton-loader>
                              <v-btn
                                v-else
                                class="mx-4 custom-bold-button white--text"
                                color="cyan"
                                small
                                v-on:click.native.stop="detailDialog = true"
                              >
                                <v-icon dark left>mdi-pencil</v-icon>Update
                                Company Details
                              </v-btn>
                            </span>
                          </template>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ContactDetail
                      :pageLoading.sync="pageLoading"
                      :subcontractor.sync="subcontractorArr"
                    ></ContactDetail>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="4" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            Contact Persons
                          </h2>
                        </v-col>
                        <v-col cols="8">
                          <span v-if="open" key="0">
                            <template
                              v-if="getPermission('contact-person:create')"
                            >
                              <span class="float-right">
                                <v-skeleton-loader
                                  class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                  v-if="pageLoading"
                                  type="button"
                                  height="28"
                                  width="200"
                                >
                                </v-skeleton-loader>
                                <v-btn
                                  v-else
                                  class="mx-2 mr-4 custom-bold-button white--text"
                                  color="cyan"
                                  small
                                  v-on:click.native.stop="personDialog = true"
                                >
                                  <v-icon dark left>mdi-plus</v-icon>Create
                                  Contact Person
                                </v-btn>
                              </span>
                            </template>
                            <template v-if="personSelectedRows.length > 0">
                              <template
                                v-if="getPermission('contact-person:update')"
                              >
                                <span class="float-right">
                                  <v-skeleton-loader
                                    class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                    v-if="pageLoading"
                                    type="button"
                                    height="28"
                                    width="150"
                                  >
                                  </v-skeleton-loader>
                                  <v-menu
                                    v-else
                                    :close-on-click="true"
                                    bottom
                                    content-class="custom-menu-list"
                                    offset-y
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        class="mx-2 custom-bold-button white--text"
                                        color="cyan"
                                        small
                                      >
                                        <v-icon small dark left
                                          >mdi-chevron-down</v-icon
                                        >Bulk Action
                                      </v-btn>
                                    </template>
                                    <v-list>
                                      <template
                                        v-for="(
                                          item, index
                                        ) in personBulkActions"
                                      >
                                        <template>
                                          <template
                                            v-if="
                                              (item.action == 'default' &&
                                                personSelectedRows.length ==
                                                  1) ||
                                              item.action != 'default'
                                            "
                                          >
                                            <v-list-item
                                              :key="index"
                                              link
                                              v-on:click="
                                                updateSubContractorPerson(item)
                                              "
                                            >
                                              <v-list-item-title
                                                class="font-weight-400 my-2 font-size-14"
                                                >{{
                                                  item.title
                                                }}</v-list-item-title
                                              >
                                            </v-list-item>
                                          </template>
                                        </template>
                                      </template>
                                    </v-list>
                                  </v-menu>
                                </span>
                              </template>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <ContactPerson
                      :pageLoading.sync="pageLoading"
                      :subcontractor.sync="subcontractorArr"
                      v-on:selectSubContractor="showUpdateDialog"
                      v-on:selectedRows="updatePersonSelectedRows"
                    ></ContactPerson>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                      <v-row no-gutters>
                        <v-col cols="6" class="margin-auto">
                          <h2
                            class="margin-auto color-custom-blue font-weight-600"
                          >
                            SubContractor Address
                          </h2>
                        </v-col>
                        <v-col cols="6">
                          <span v-if="open" key="0" class="float-right">
                            <template
                              v-if="getPermission('subcontractor:update')"
                            >
                              <v-skeleton-loader
                                class="custom-skeleton d-inline-block custom-skeleton-full-width"
                                v-if="pageLoading"
                                type="button"
                                height="28"
                                width="200"
                              >
                              </v-skeleton-loader>
                              <v-btn
                                v-else
                                class="mx-2 mr-4 custom-bold-button white--text"
                                color="cyan"
                                small
                                v-on:click.native.stop="billingDialog = true"
                              >
                                <v-icon dark left>mdi-pencil</v-icon>Update
                                SubContractor Address
                              </v-btn>
                            </template>
                          </span>
                        </v-col>
                      </v-row>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <BillingAddress
                      :pageLoading.sync="pageLoading"
                      :subcontractor.sync="subcontractorArr"
                    ></BillingAddress>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-row>
          </v-tab-item>
          <v-tab-item v-if="getPermission('history:view')" :value="'history'">
            <InternalHistoryDetail
              type="subcontractor"
              :type_id.sync="subcontractorArr.id"
            ></InternalHistoryDetail>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>
    </template>
    <template v-slot:footer>
      <Dialog
        v-if="detailDialog"
        :commonDialog="detailDialog"
        :dialogWidth="1024"
      >
        <template v-slot:title>Update Contact Details</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('subcontractor:update'),
            }"
            ref="subcontractorDetailForm"
            v-model.trim="detailForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('subcontractorDetailForm')"
          >
            <DialogContactDetails
              :subcontractor.sync="subcontractorArr"
              :isDialog="true"
              v-on:saveContactDetail="updateContactDetail"
            >
            </DialogContactDetails>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('subcontractor:update')">
            <v-btn
              :disabled="!detailForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('subcontractorDetailForm')"
              >Update
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog
        v-if="personDialog"
        :commonDialog="personDialog"
        :dialogWidth="768"
      >
        <template v-slot:title>Contact Person</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('contact-person:update'),
            }"
            ref="subcontractorPersonForm"
            v-model.trim="personForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('subcontractorPersonForm')"
          >
            <DialogContactPersons
              :subcontractor.sync="subcontractorArr"
              :isDialog="true"
              :person="personId"
              v-on:saveContactPerson="updateContactPerson"
            >
            </DialogContactPersons>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('contact-person:update')">
            <v-btn
              :disabled="!personForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('subcontractorPersonForm')"
            >
              <span v-if="personId > 0">Update</span>
              <span v-else>Create</span>
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog
        v-if="billingDialog"
        :commonDialog="billingDialog"
        :dialogWidth="1024"
      >
        <template v-slot:title>SubContractor Address</template>
        <template v-slot:body>
          <v-form
            :class="{
              'pointer-events-none': !getPermission('subcontractor:update'),
            }"
            ref="billingForm"
            v-model.trim="billingForm"
            lazy-validation
            v-on:submit.stop.prevent="onDetailSubmit('billingForm')"
          >
            <DialogBillingAddress
              :subcontractor.sync="subcontractorArr"
              :isDialog="true"
              :property.sync="propertyAddress"
              :billing="1"
              v-on:saveBillingAddress="updateBillingAddress"
            >
            </DialogBillingAddress>
          </v-form>
        </template>
        <template v-slot:action>
          <template v-if="getPermission('subcontractor:update')">
            <v-btn
              :disabled="!billingForm || formLoading"
              :loading="formLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
              v-on:click="onDetailSubmit('billingForm')"
              >Update
            </v-btn>
          </template>
          <v-btn
            :disabled="formLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="masterDialogClose"
            >Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PUT, PATCH, CLEAR_ERROR } from "@/core/services/store/request.module";
import ContactDetail from "@/view/pages/subcontractor/detail/overview/Contact-Detail.vue";
import ContactPerson from "@/view/pages/subcontractor/detail/overview/Contact-Person.vue";
import BillingAddress from "@/view/pages/subcontractor/detail/overview/Billing-Address.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import DialogContactDetails from "@/view/pages/subcontractor/create-or-update/Contact-Details.vue";
import DialogContactPersons from "@/view/pages/subcontractor/create-or-update/Contact-Persons.vue";
import DialogBillingAddress from "@/view/pages/subcontractor/create-or-update/Billing-Address.vue";
import SubContractorMixin from "@/core/plugins/subcontractor-mixin";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import PatchSubContractorMixin from "@/core/lib/subcontractor/patch.subcontractor.mixin";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";

export default {
  mixins: [
    CommonMixin,
    ValidationMixin,
    SubContractorMixin,
    PatchSubContractorMixin,
  ],
  name: "subcontractor-detail",
  title: "SubContractor",
  data() {
    return {
      subcontractor: 0,
      serviceFormDialog: false,
      pageLoading: true,
      deleteDialog: false,
      subcontractorArr: {},
      tabs: [
        {
          title: "Overview",
          icon: "mdi-phone",
          key: "subcontractor",
          disabled: false,
        },
        {
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
      ],
      createTransactions: [
        {
          title: "Quotation",
          action: "quotation",
        },
        {
          title: "Invoice",
          action: "invoice",
        },
        {
          title: "Property",
          action: "property",
        },
      ],
      subcontractorMoreAction: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
      //subcontractorTab: null,
      panel: 0,
      personSelectedRows: [],
      propertySelectedRows: [],
      propertyAddress: {},
      billingAddress: {},
      uBillingAddress: {},
      contactDetail: {},
      contactPerson: [],
      notification: {},
      detailForm: true,
      detailDialog: false,
      formLoading: false,
      personId: 0,
      personForm: true,
      personDialog: false,
      propertyId: 0,
      propertyForm: true,
      propertyDialog: false,
      billingForm: true,
      billingDialog: false,
      notificationForm: true,
      notificationDialog: false,
    };
  },
  components: {
    ContactDetail,
    ContactPerson,
    BillingAddress,
    Dialog,
    DialogContactDetails,
    DialogContactPersons,
    DialogBillingAddress,
    DetailTemplate,
    InternalHistoryDetail,
  },
  watch: {
    panel() {
      this.masterDialogClose();
    },
  },
  methods: {
    updateMoreAction(action) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
      }
      _this
        .patchSubContractor(_this.subcontractor, formData)
        .then(() => {
          _this.getSubContractor().then((response) => {
            _this.subcontractorArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updatePropertyAddress(param) {
      this.propertyAddress = param;
    },
    updateBillingAddress(param) {
      this.billingAddress = param;
    },
    updateContactDetail(param) {
      this.contactDetail = param;
    },
    updateContactPerson(param) {
      this.contactPerson = param;
    },
    updateNotification(param) {
      this.notification = param;
    },
    updatePersonSelectedRows(param) {
      this.bulkRows = { persons: param };
      this.personSelectedRows = param;
    },
    updatePropertySelectedRows(param) {
      this.bulkRows = { property: param };
      this.propertySelectedRows = param;
    },
    updateSubContractorPerson({ action }) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
        case "default":
          formData.default = 1;
          break;
      }
      _this
        .patchSubContractorContactPerson(
          _this.subcontractor,
          _this.personSelectedRows,
          formData
        )
        .then(() => {
          _this.getSubContractor().then((response) => {
            _this.subcontractorArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    updateSubContractorProperty({ action }) {
      const _this = this;
      let formData = {};
      switch (action) {
        case "active":
          formData.status = 1;
          break;
        case "inactive":
          formData.status = 0;
          break;
      }
      _this
        .patchSubContractorProperty(
          _this.subcontractor,
          _this.propertySelectedRows,
          formData
        )
        .then(() => {
          _this.getSubContractor().then((response) => {
            _this.subcontractorArr = response;
          });
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    async onDetailSubmit(formRef) {
      const _this = this;

      try {
        if (!_this.$refs[formRef].validate()) {
          return false;
        }

        _this.pageLoading = true;
        _this.formLoading = true;
        _this.$store.dispatch(CLEAR_ERROR, {});

        let formData = new Object();
        let personFormData = new Object();
        let detailFormData = new Object();
        let addressFormData = new Array();

        if (_this.lodash.isEmpty(_this.contactDetail) === false) {
          for (let key in _this.contactDetail) {
            formData[key] = _this.contactDetail[key];
          }

          if (_this.lodash.isEmpty(formData.profile_logo) === false) {
            formData.profile_logo = formData.profile_logo.id;
          }
        }

        if (_this.lodash.isEmpty(_this.contactDetail) === false) {
          if (
            Object.prototype.hasOwnProperty.call(
              _this.contactDetail,
              "details"
            ) &&
            _this.lodash.isEmpty(_this.contactDetail.details) === false
          ) {
            detailFormData = _this.contactDetail.details;
          }
        }

        if (_this.lodash.isEmpty(_this.notification) === false) {
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "client_reminders"
            )
          ) {
            formData.client_reminders = _this.notification.client_reminders;
          }
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "client_follow_up_emails"
            )
          ) {
            formData.client_follow_up_emails =
              _this.notification.client_follow_up_emails;
          }
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "print_email_on_work_order"
            )
          ) {
            formData.print_email_on_work_order =
              _this.notification.print_email_on_work_order;
          }
          if (
            Object.prototype.hasOwnProperty.call(
              _this.notification,
              "subcontractor_remark"
            )
          ) {
            formData.subcontractor_remark =
              _this.notification.subcontractor_remark;
          }
        }

        if (_this.lodash.isEmpty(_this.contactPerson) === false) {
          personFormData = _this.contactPerson;
        }

        if (_this.lodash.isEmpty(_this.propertyAddress) === false) {
          addressFormData.push(_this.propertyAddress);
        }

        if (_this.lodash.isEmpty(_this.billingAddress) === false) {
          addressFormData.push(_this.billingAddress);
        }

        //formData = _this.lodash.pickBy(formData, _this.lodash.identity);
        addressFormData = _this.lodash.pickBy(
          addressFormData,
          _this.lodash.identity
        );

        if (_this.lodash.isEmpty(formData) === false) {
          await _this.$store.dispatch(PATCH, {
            url: "subcontractor/" + _this.subcontractor,
            data: formData,
          });
        }

        if (_this.lodash.isEmpty(personFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "subcontractor/" + _this.subcontractor + "/contact-person",
            data: { persons: personFormData },
          });
        }

        if (_this.lodash.isEmpty(detailFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "subcontractor/" + _this.subcontractor + "/detail",
            data: { details: detailFormData },
          });
        }

        if (_this.lodash.isEmpty(addressFormData) === false) {
          await _this.$store.dispatch(PUT, {
            url: "address/" + _this.subcontractor,
            data: { address: addressFormData, type: "subcontractor" },
          });
        }

        _this
          .getSubContractor()
          .then((response) => {
            _this.subcontractorArr = response;
            _this.masterDialogClose();
          })
          .finally(() => {
            _this.formLoading = false;
            _this.pageLoading = false;
          });
      } catch (error) {
        _this.formLoading = false;
        _this.pageLoading = false;
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    showUpdateDialog(param) {
      this.personId = param;
      this.personDialog = true;
    },
    showPropertyDialog(param) {
      this.propertyId = param;
      this.propertyDialog = true;
    },
    masterDialogClose() {
      this.$root.$emit("clearSelection", true);
      this.personSelectedRows = [];
      this.propertySelectedRows = [];
      this.propertyAddress = {};
      this.billingAddress = {};
      this.contactDetail = {};
      this.contactPerson = [];
      this.notification = {};
      this.detailForm = true;
      this.detailDialog = false;
      this.formLoading = false;
      this.personId = 0;
      this.personForm = true;
      this.personDialog = false;
      this.propertyId = 0;
      this.propertyForm = true;
      this.propertyDialog = false;
      this.billingForm = true;
      this.billingDialog = false;
      this.notificationForm = true;
      this.notificationDialog = false;
      this.deleteDialog = false;
    },
  },
  mounted() {
    const _this = this;
    _this.checkTransactionPermission();
    _this.checkTabPermission();
    _this
      .getSubContractor()
      .then((response) => {
        _this.subcontractorArr = response;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { title: "SubContractor", route: "subcontractor" },
          { title: "Detail" },
          { title: response.barcode },
        ]);
      })
      .catch((error) => {
        _this.logError(error);
        _this.$router.go(-1);
      })
      .finally(() => {
        _this.pageLoading = false;
      });
  },
  created() {
    const _this = this;
    _this.subcontractor = _this.$route.params.id;
    if (!_this.subcontractor || _this.subcontractor <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.subcontractorTab = _this.$route.query.tab;
    }
  },
  computed: {
    getPageTitle() {
      let result = [];
      if (this.lodash.isEmpty(this.subcontractorArr) === false) {
        if (this.subcontractorArr.display_name) {
          result.push(this.subcontractorArr.display_name);
        }
        if (this.subcontractorArr.barcode) {
          result.push(this.subcontractorArr.barcode);
        }
      }
      return result.join(" • ");
    },
    subcontractorTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.subcontractorTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "subcontractor";
      },
    },
  },
};
</script>
