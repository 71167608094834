import { GET, QUERY } from "@/core/services/store/request.module";
import { ErrorEventBus } from "@/core/lib/message.lib";

export default {
  data() {
    return {
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_property_dialog: false,
      transaction_billing: 0,
      transaction_subcontractor: 0,
      transaction_contact_person: 0,
      transaction_property: 0,
    };
  },
  methods: {
    createSubContractor() {},
    createContactPerson() {},
    createSubContractorProperty() {},
    getSubContractors(search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "subcontractor-list",
              data: { search: search },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getSubContractorPersons(subcontractor, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (subcontractor <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "contact-person-list/" + subcontractor,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getSubContractorProperties(subcontractor, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (subcontractor <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "property-list/" + subcontractor,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getSubContractor() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.masterDialogClose();
          _this.$store
            .dispatch(GET, { url: "subcontractor/" + _this.subcontractor })
            .then((response) => {
              let billingAddress = _this.getBillingAddress(response.data);
              _this.transaction_billing = billingAddress.id;
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              let result = _this.getBillingAddress(_this.subcontractorArr);
              if (_this.lodash.isEmpty(result) === false) {
                _this.uBillingAddress = result;
                _this.uBillingAddress.country = result.country_name;
              }
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(subcontractor) {
      let defaultPerson = {};
      const _this = this;
      if (
        subcontractor &&
        _this.lodash.isEmpty(subcontractor.persons) === false
      ) {
        for (let i = 0; i < subcontractor.persons.length; i++) {
          if (subcontractor.persons[i].default) {
            defaultPerson = _this.lodash.cloneDeep(subcontractor.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPersonById(subcontractor, id) {
      let defaultPerson = {};
      const _this = this;
      if (
        subcontractor &&
        _this.lodash.isEmpty(subcontractor.persons) === false
      ) {
        for (let i = subcontractor.persons.length - 1; i >= 0; i--) {
          if (subcontractor.persons[i].id == id) {
            defaultPerson = _this.lodash.cloneDeep(subcontractor.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPropertyById(subcontractor, id) {
      let property = {};
      const _this = this;
      if (
        subcontractor &&
        _this.lodash.isEmpty(subcontractor.address) === false
      ) {
        for (let i = subcontractor.address.length - 1; i >= 0; i--) {
          if (subcontractor.address[i].id == id) {
            property = _this.lodash.cloneDeep(subcontractor.address[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(property);
    },
    getBillingAddress(subcontractor) {
      let billing = {};
      const _this = this;
      if (
        subcontractor &&
        _this.lodash.isEmpty(subcontractor.address) === false
      ) {
        for (let i = subcontractor.address.length - 1; i >= 0; i--) {
          if (subcontractor.address[i].type == 2) {
            billing = _this.lodash.cloneDeep(subcontractor.address[i]);
            break;
          }
        }
      }
      return _this.lodash.cloneDeep(billing);
    },
    getEmailDetails(subcontractor) {
      let emailArr = [];
      const _this = this;
      if (
        subcontractor &&
        _this.lodash.isEmpty(subcontractor.details) === false
      ) {
        for (let i = 0; i < subcontractor.details.length; i++) {
          if (subcontractor.details[i].type === 1) {
            emailArr.push(subcontractor.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(emailArr);
    },
    getPhoneDetails(subcontractor) {
      let phoneArr = [];
      const _this = this;
      if (
        subcontractor &&
        _this.lodash.isEmpty(subcontractor.details) === false
      ) {
        for (let i = 0; i < subcontractor.details.length; i++) {
          if (subcontractor.details[i].type === 2) {
            phoneArr.push(subcontractor.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(phoneArr);
    },
    resetAll() {
      this.transaction_subcontractor = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.transaction_contact_person_dialog = false;
      this.transaction_property_dialog = false;
    },
    selectSubContractorPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;
      if (this.transaction_property <= 0) {
        this.transaction_property_dialog = true;
      }
      this.checkTransaction();
    },
    selectSubContractorProperty(property) {
      this.transaction_property = this.lodash.toSafeInteger(property);
      this.checkTransaction();
    },
    checkTransaction() {
      const _this = this;
      let subcontractor = _this.lodash.toSafeInteger(
        _this.transaction_subcontractor
      );
      let contact_person = _this.lodash.toSafeInteger(
        _this.transaction_contact_person
      );
      let property = _this.lodash.toSafeInteger(_this.transaction_property);

      let billing = _this.lodash.toSafeInteger(_this.transaction_billing);

      if (_this.transaction_type == "quotation") {
        if (!billing) {
          ErrorEventBus.$emit(
            "update:error",
            "SubContractor doesn't have billing address. Please update then try again."
          );
          return false;
        } else {
          _this.$router.push(
            _this.getDefaultRoute(_this.transaction_type + ".create", {
              query: {
                subcontractor,
                contact_person,
                billing,
              },
            })
          );
          return true;
        }
      }

      if (subcontractor > 0 && contact_person > 0 && property > 0) {
        _this.$router.push(
          _this.getDefaultRoute(_this.transaction_type + ".create", {
            query: {
              subcontractor,
              contact_person,
              property,
            },
          })
        );
      }
    },
    createTransaction(subcontractor, param) {
      const _this = this;
      _this.transaction_subcontractor = _this.lodash.toSafeInteger(
        subcontractor.id
      );
      _this.transaction_type = _this.lodash.toString(param);

      if (_this.transaction_type === "property") {
        _this.$router.push(
          _this.getDefaultRoute("property.create", {
            query: { subcontractor: _this.transaction_subcontractor },
          })
        );
      } else {
        if (subcontractor.persons_count > 1) {
          _this.transaction_contact_person_dialog = true;
        } else if (subcontractor.property_count > 1) {
          _this.transaction_property_dialog = true;
        }

        if (subcontractor.persons_count == 1) {
          let firstPerson = _this.lodash.head(subcontractor.persons);
          _this.transaction_contact_person = firstPerson.id;
        }

        if (subcontractor.property_count == 1) {
          let firstProperty = _this.lodash.head(subcontractor.property);
          _this.transaction_property = firstProperty.id;
        }

        _this.checkTransaction();
      }
    },
  },
};
